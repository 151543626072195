import React, { Component } from 'react';
//Packages
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet';
//Images

class Privacypage extends Component {
  render() { return(
    <div className="privacy-policy">

    <Helmet>
    <title>Lykke Haus - Privacy Policy</title>
    <meta name="description" content="Our Privacy Policy. Lykke Haus - Property management for those looking for a remarkable standard of service." />
    <meta property="og:title" content="Lykke Haus - Privacy Policy" />
    <meta property="og:description" content="Our Privacy Policy. Lykke Haus - Property management for those looking for a remarkable standard of service." />
    <meta property="og:image" content="https://lykkehaus.com/og-image.jpg"/>
    </Helmet>
  <div className="parallax-home-200" id="privacy-page-header"/>
    <Container>
    <Row className="mb-5">
    <Col xs="12" className="mb-5">
    <h3 className="intro-text mb-4 mt-5">Scope</h3>
    <p>This policy applies to the websites and services of Lykke Haus.</p>
    <p>It covers our website and what we do with information we gather when you visit our website but, if we’ve given you a separate agreement containing a different privacy policy, the data policy of the separate agreement will override this policy.</p>
    <p>This privacy policy explains how we use any personal data you give us, and how we might use trackers, cookies or other services. It also explains how we treat your data on our equipment.</p>
    <h3 className="intro-text mb-4">Data Control and Contact</h3>
    <p>We take your privacy very seriously. If you have any questions about this privacy policy, would like to make any requests or remove your data, <a href="/contact">contact us</a>.</p>
    <p>We will endeavour to respond within one week, and at most within 29 days.</p>
    <p>As a European company, we are subject to the General Data Protection Regulations (GDPR).</p>
    <h3 className="intro-text mb-4">Changes and Revisions</h3>
    <p>We re-evaluate this policy on an ongoing basis and we reserve the right to make changes to this privacy policy as required. However, if there are any changes to the use of personal information that is different from that stated at the time of collection, we will notify you by posting a notice on this Website and seek your consent.</p>
    <p>This privacy policy was last amended 8th May 2020.</p>
    <h3 className="intro-text mb-4">Cookies and Trackers</h3>
    <p>We utilize cookies and trackers for several purposes outlined in this section. When you first accessed our website, you should have been shown a cookie notice asking you to accept our terms which included a consent button and a link to our privacy policy. If you think we have inadvertently gathered your data, please contact us immediately so we can remove it.</p>
    <p className="indent">-Managing Logins and Authentication: Cookies are an essential part of some of our processes. They are saved to your computer as part of session verification. We do not use these cookies for marketing or other information gathering but if you disable them you may find some of our websites features do not work.
    <br/>-Analytics: We use cookies and trackers to gather information on our how you use our website, which websites you came to our website from and other key marketing information. We use google analytics for this, and you can read about their cookies <a href="https://support.google.com/analytics/#topic=3544906">specifically here</a>.
    <br/>-Marketing: We may use cookies and trackers to retarget visitors on our website for advertising purposes. We may target you through services like Facebook, using the Facebook pixel, or ad trackers for google ads. These are third party services and we do not actually hold personally identifiable information on you in these cases.</p>
    <p>You can read more about cookies and trackers, your privacy, and how to block / delete cookies by visiting your web-browser software’s documentation.</p>
    <p>By clicking accept on our cookie notice and subsequently using our website, you accept these cookies, but you can contact us at any time for assistance if you change your mind.</p>
    <h3 className="intro-text mb-4">Information required for your services</h3>
    <p>We must gather personal information about you for billing and service delivery. This information is not used for marketing purposes and may include name, address, contact details, IP Address (for fraud prevention) and other information you choose to give us. We store this information for at least as long as you are our customer, and we may store this information for longer in line with whatever legal requirements arise, such as business record keeping. We do not want to keep your data longer than required, and so we will delete it as soon as possible.</p>
    <p>If, after we provide services, you would like us to remove this data we will need to make an assessment on a case-by-case basis in line with legal requirements.</p>
    <h3 className="intro-text mb-4">Security</h3>
    <p>We have an SSL everywhere policy meaning all data entered our website, including personal and payment information, is protected by encryption. We use firewalls, access control, service separation, encryption and strong passwords to help keep security robust, amongst other measures.</p>
    </Col>
    </Row>
    </Container>
    </div>
  );
}
}

export default Privacypage;
