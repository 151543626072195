import React, { Component } from 'react';
//Packages
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
//Images
class FurniturePage extends Component {
    render() { return(
        <div className="furniture-rental-page">

        <Helmet>
        <title>Lykke Haus - Furniture Rental</title>
        <meta name="description" content="Lykke Haus  offers a high-end yet low-cost home staging solution for all your property needs." />
        <meta property="og:title" content="Lykke Haus - Furniture Rental" />
        <meta property="og:description" content="Lykke Haus  offers a high-end yet low-cost home staging solution for all your property needs." />
        <meta property="og:image" content="https://lykkehaus.com/og-image.jpg"/>
        </Helmet>

        <Container>
            <Row className="mt-5 mb-5">
                <Col lg="6">
                    <div className="equalize">
                        <h1>Furniture Rental</h1>
                        <p>Lykke Haus  offers a high-end yet low-cost home staging solution for all your property needs.</p>
                        <p>Our in-house design team will ensure your property is dressed to sell or let at maximum value by creating a sense of style and focusing on the key selling points.</p>
                    </div>
                </Col>
                <Col lg="6">
                <Image src="/pub-images/living-room-furniture-rental.jpg" fluid className="p-3"/>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col lg="6">
                <Image src="/pub-images/artillery-road-bedroom-furniture-rental.jpg" fluid className="p-3"/>
                </Col>
                <Col lg="6">
                    <div className="equalize">
                        <p>Without fixed packages, each scheme is tailor-made to the specific property, creating eye-catching interiors for every project. We understand that selling or letting a property can be stressful, therefore we pride ourselves on delivering exceptional results both on time and on brief.</p>
                    </div>
                </Col>
            </Row>
            <Row className="mb-5 text-center">
                <Col>
                    <h5>Prices start from £69 per room per month.</h5>
                    <Link to="/contact/">
                    <Button variant="dark" size="md" className="blog-button">Contact us to Discuss</Button>
                    </Link>    
                </Col>
            </Row>
        </Container>
        </div>
        );
      }
      }
      
      export default FurniturePage;