import React, { Component } from 'react';
//Packages
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

class FeesPage extends Component {
  render() { return(
    <div className="ServicePage">

    <Helmet>
    <title>Lykke Haus - Our Fees</title>
    <meta name="description" content="Our fee schedule for both landlords and tenants. Lykke Haus offers transparent fees for our clients in property management, Single Lets and luxury co-living." />
    <meta property="og:title" content="Lykke Haus - Our Fees" />
    <meta property="og:description" content="Our fee schedule for both landlords and tenants. Lykke Haus offers transparent fees for our clients in property management, Single Lets and luxury co-living." />
    <meta property="og:image" content="https://lykkehaus.com/og-image.jpg"/>
    </Helmet>
  <div className="parallax-home-200" id="fees-page-header"/>
    <Container>
    <Row>
    <Col className="ml-auto mr-auto mt-5 mb-3 text-center">
    <h3 className="text-center">Transparent Fees for Tenants and Landlords</h3>
    <Card style={{ width: '80%' }} className="ml-auto mr-auto">
    <Card.Header>Tenant fees</Card.Header>
    <ListGroup variant="flush">
    <ListGroup.Item><strong>Application fee:</strong> £0 per tenant</ListGroup.Item>
    <ListGroup.Item><strong>Reference fees:</strong> £0 per tenant</ListGroup.Item>
    <ListGroup.Item><strong>Deposit:</strong> 4 weeks rent, unless otherwise agreed</ListGroup.Item>
    <ListGroup.Item><strong>Tenancy renewal fee:</strong> £0</ListGroup.Item>
    <ListGroup.Item><strong>Check-in fee:</strong> £0</ListGroup.Item>
    <ListGroup.Item><strong>Check-out fee:</strong> £0</ListGroup.Item>
    </ListGroup>
    </Card>
    <Card style={{ width: '80%' }} className="ml-auto mr-auto mt-4">
    <Card.Header>Landlord fees</Card.Header>
    <ListGroup variant="flush">
    <ListGroup.Item><strong>Tenant Finding</strong> – inside London 15% of yearly rent; outside London 12% of yearly rent; HMO rooms – one month's rent</ListGroup.Item>
    <ListGroup.Item><strong>Property introduction & set up fee:</strong> £350 –setting the tenancy up, drafting tenancy agreement & referencing up to 2 tenants</ListGroup.Item>
    <ListGroup.Item><strong>Letting fee:</strong>£180 – every subsequent time we find tenants for you & includes all marketing, negotiation & tenancy drafting – HMOs only </ListGroup.Item>
    <ListGroup.Item><strong>Inventory fee:</strong> £150 – full inventory with photos</ListGroup.Item>
    <ListGroup.Item><strong>Full management:</strong> outside London 12%, inside London 15%, minimum charge of £200 applies from 01/08/2019</ListGroup.Item>
    <ListGroup.Item><strong>Tenancy renewal fee:</strong> £0</ListGroup.Item>
    <ListGroup.Item><strong>Commission on maintenance arranged:</strong> 0%</ListGroup.Item>
    <ListGroup.Item><strong>Check in/out fee:</strong> £0</ListGroup.Item>
    <ListGroup.Item><strong>Arranging Gas Safety Certificate:</strong> £0 with fully managed service</ListGroup.Item>
    <ListGroup.Item><strong>There are no other hidden fees</strong></ListGroup.Item>
    </ListGroup>
    </Card>
    </Col>
    </Row>
    <Row>
    <Col className="text-center mb-5">
    <p>For properties where the local authority require a mandatory license, we charge £49 to complete the application paperwork.</p>
    <p>We do not charge <strong>any</strong> fees additional to those listed, other than passing on the actual cost of any safety inspections or certificates you request.</p>
    <Link to="/contact/">
    <Button variant="dark" size="md" className="blog-button">Contact Us</Button>
    </Link>
    </Col>
    </Row>
    </Container>
</div>

);
}
}

export default FeesPage;
