import React, { Component } from 'react';
//Elements
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
//Images
import Logo from '../images/logo.svg';

class Navigationbar extends Component {
  render() { return(
    <div>
    <Navbar variant="light" expand="xl">
    <Navbar.Brand href="/"> <img src={Logo} className="logo" alt="logo" /></Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="ml-auto mr-2">
    <Nav.Link href="/">Home</Nav.Link>
    <Nav.Link href="/about">About</Nav.Link>
    <Nav.Link href="/services">Our Service</Nav.Link>
    <Nav.Link href="/furniture-rental">Furniture</Nav.Link>
    <Nav.Link href="/fees">Fees</Nav.Link>
    <Nav.Link href="/faq">F.A.Q</Nav.Link>
    <Nav.Link href="/blog">Blog</Nav.Link>
    <Nav.Link href="/contact">Contact</Nav.Link>
    </Nav>
    </Navbar.Collapse>
    </Navbar>
    </div>
  );
}
}

export default Navigationbar;
