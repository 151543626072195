import React, { Component } from 'react';
//Packages
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet';
//Contact form
import ContactForm from '../includes/contactform.js';

class ContactPage extends Component {
  render() { return(
    <div className="ContactPage">

    <Helmet>
    <title>Lykke Haus - Contact Us</title>
    <meta name="description" content="Luxurious, boutique accommodation designed with you in mind. Property management for those looking for a remarkable standard of service. Based in Thanet, Kent." />
    <meta property="og:title" content="Lykke Haus - Contact Us" />
    <meta property="og:description" content="Luxurious, boutique accommodation designed with you in mind. Property management for those looking for a remarkable standard of service. Based in Thanet, Kent." />
    <meta property="og:image" content="https://lykkehaus.com/og-image.jpg"/>
    </Helmet>
  <div className="parallax-home-200"/>
    <Container className="min-div">
    <Row>
    <Col className="text-center mt-5 mb-5 pb-5 pt-5">
    <h1 className="mt-4 mb-4">Contact Us</h1>
    <p>Please give us a call on <a href="tel:0441843265040">01843 265040</a> or contact us by email on <a href="mailto:maria@lykkehaus.com">maria@lykkehaus.com</a>.</p>
    </Col>
    </Row>
    </Container>
</div>

);
}
}

export default ContactPage;
