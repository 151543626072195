import React, { Component } from 'react';
//Packages
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

class FAQpage extends Component {
  render() { return(
    <div className="ServicePage">

    <Helmet>
    <title>Lykke Haus - Frequently Asked Questions</title>
    <meta name="description" content="Frequently asked questions about our service from tenants and landlords. Lykke Haus is a provider of luxury coliving, Single Lets, property management and shared accomodation." />
    <meta property="og:title" content="Lykke Haus - Frequently Asked Questions" />
    <meta property="og:description" content="Frequently asked questions about our service from tenants and landlords. Lykke Haus is a provider of luxury coliving, Single Lets, property management and shared accomodation." />
    <meta property="og:image" content="https://lykkehaus.com/og-image.jpg"/>
    </Helmet>
  <div className="parallax-home-200" id="FAQ-page-header"/>
    <Container>
    <Row>
    <Col className="ml-auto mr-auto mt-5 mb-3 text-center">
    <Accordion defaultActiveKey="0">
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="0">
      Can I add an additional person to my licence?
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0">
      <Card.Body>Yes, however the additional tenant must be vetted in the usual way via an application form and interview process. You must also have an up to date rent account and be able to pay the administration fee of £50. The rent for the accommodation will usually be increased if an additional tenant moves in.</Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="1">
      How do I obtain another copy of my licence?
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="1">
      <Card.Body>We can print off a copy of your license agreement for free upon request. Original documents can also be produced at a cost of £25.</Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="2">
      Am I allowed a pet in my property?
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="2">
      <Card.Body>Pets are not suitable for rooms in shared houses; however we do sometimes allow pets in studios which have direct access to a garden/yard. There is also a one-off supplement fee of £50 payable to have a pet within your accommodation.</Card.Body>
    </Accordion.Collapse>
  </Card>
</Accordion>
    <Link to="/contact/">
    <Button variant="dark" size="md" className="blog-button">Further Questions?</Button>
    </Link>
    </Col>
    </Row>
    </Container>
</div>

);
}
}

export default FAQpage;
