//This pulls blog posts from a WordPress API somewhere, using Axios. The other related file is Postview.js.
import React, { Component } from 'react'; //import the component part of React
//Packages
import axios from "axios";
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet';

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: []
    };
    this.createMarkup = this.createMarkup.bind();
  }

  componentDidMount() {
    axios.get("https://lykkehaus.com/wp/wp-json/wp/v2/posts").then(posts => {
      this.setState({
        posts: posts.data
      });
    });
  }

  createMarkup(html) {
    return { __html: html };
  }

  render() {
    return (
      <div className="blog-archive">
      <Helmet>
      <title>Lykke Haus - The Lykke Blog</title>
      <meta name="description" content="Lykke Haus is a luxury property manager, provider of co-living and Single Lets in the South East of England and London. Read our blog to get our latest insights." />
      <meta property="og:title" content="Lykke Haus - The Lykke Blog" />
      <meta property="og:description" content="Lykke Haus is a luxury property manager, provider of co-living and Single Lets in the South East of England and London. Read our blog to get our latest insights." />
      <meta property="og:image" content="https://lykkehaus.com/og-image.jpg"/>
      </Helmet>
        <div className="parallax-home-200" id="blog-header"/>
      <Container className="min-div">
      <Row className="mb-3 pb-3">
      <Col xs="12" className="text-center mt-4">
      <h1 className="blog-page-title">The Lykke Blog</h1>
      </Col>
      </Row>
      {this.state.posts.map(post => (
        <Link to={`/blog/${post.slug}`} key={post.id}>
        <div key={post.id}>
        <Row className="pt-3 pb-3 mt-2">
        <Col xs="12" className="text-center">
        <h3 className="intro-text text-center">{post.title.rendered}</h3>
        <p className="mb-3 blog-excerpt" dangerouslySetInnerHTML={this.createMarkup( post.excerpt.rendered )} />
        </Col>
        </Row>
        </div>
        </Link>
      ))}
      </Container>
      </div>
    );
  }
}

export default Blog;
