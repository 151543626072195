import React, { Component } from "react";
//Packages
import ImageGallery from "react-image-gallery";

const images = [
  {
    original: "/pub-images/margate/margate_road_1.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_1.jpg",
  },
  {
    original: "/pub-images/margate/margate_road_2.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_2.jpg",
  },
  {
    original: "/pub-images/margate/margate_road_3.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_3.jpg",
  },
  {
    original: "/pub-images/margate/margate_road_4.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_4.jpg",
  },
  {
    original: "/pub-images/margate/margate_road_5.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_5.jpg",
  },
  {
    original: "/pub-images/new-build/7.jpg",
    thumbnail: "/pub-images/new-build/thumbnails/7_thumbnail.jpg",
  },
  {
    original: "/pub-images/new-build/8.jpg",
    thumbnail: "/pub-images/new-build/thumbnails/8_thumbnail.jpg",
  },
  {
    original: "/pub-images/new-build/9.jpg",
    thumbnail: "/pub-images/new-build/thumbnails/9_thumbnail.jpg",
  },
  {
    original: "/pub-images/new-build/10.jpg",
    thumbnail: "/pub-images/new-build/thumbnails/10_thumbnail.jpg",
  },
  {
    original: "/pub-images/new-build/11.jpg",
    thumbnail: "/pub-images/new-build/thumbnails/11_thumbnail.jpg",
  },
  {
    original: "/pub-images/new-build/12.jpg",
    thumbnail: "/pub-images/new-build/thumbnails/12_thumbnail.jpg",
  },
  {
    original: "/pub-images/margate/margate_road_6.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_6.jpg",
  },
  {
    original: "/pub-images/margate/margate_road_7.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_7.jpg",
  },
  {
    original: "/pub-images/margate/margate_road_8.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_8.jpg",
  },
  {
    original: "/pub-images/margate/margate_road_9.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_9.jpg",
  },
  {
    original: "/pub-images/margate/margate_road_10.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_10.jpg",
  },
  {
    original: "/pub-images/margate/margate_road_11.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_11.jpg",
  },
  {
    original: "/pub-images/margate/margate_road_12.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_12.jpg",
  },
  {
    original: "/pub-images/margate/margate_road_13.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_13.jpg",
  },
  {
    original: "/pub-images/margate/margate_road_14.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_14.jpg",
  },
  {
    original: "/pub-images/margate/margate_road_15.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_15.jpg",
  },
  {
    original: "/pub-images/margate/margate_road_16.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_16.jpg",
  },
  {
    original: "/pub-images/margate/margate_road_17.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_17.jpg",
  },
  {
    original: "/pub-images/margate/margate_road_18.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_18.jpg",
  },
  {
    original: "/pub-images/new-build/1.jpg",
    thumbnail: "/pub-images/new-build/thumbnails/1_thumbnail.jpg",
  },
  {
    original: "/pub-images/new-build/2.jpg",
    thumbnail: "/pub-images/new-build/thumbnails/2_thumbnail.jpg",
  },
  {
    original: "/pub-images/new-build/3.jpg",
    thumbnail: "/pub-images/new-build/thumbnails/3_thumbnail.jpg",
  },
  {
    original: "/pub-images/new-build/4.jpg",
    thumbnail: "/pub-images/new-build/thumbnails/4_thumbnail.jpg",
  },
  {
    original: "/pub-images/new-build/5.jpg",
    thumbnail: "/pub-images/new-build/thumbnails/5_thumbnail.jpg",
  },
  {
    original: "/pub-images/new-build/6.jpg",
    thumbnail: "/pub-images/new-build/thumbnails/6_thumbnail.jpg",
  },
  {
    original: "/pub-images/new_picture_1.jpg",
    thumbnail: "/pub-images/thumbnails/new_picture_1_thumbnail.jpg",
  },
  {
    original: "/pub-images/new_picture_2.jpg",
    thumbnail: "/pub-images/thumbnails/new_picture_2_thumbnail.jpg",
  },
  {
    original: "/pub-images/new_picture_3.jpg",
    thumbnail: "/pub-images/thumbnails/new_picture_3_thumbnail.jpg",
  },
  {
    original: "/pub-images/new_picture_4.jpg",
    thumbnail: "/pub-images/thumbnails/new_picture_4_thumbnail.jpg",
  },
  {
    original: "/pub-images/margate/margate_road_19.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_19.jpg",
  },
  {
    original: "/pub-images/margate/margate_road_20.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_20.jpg",
  },
  {
    original: "/pub-images/margate/margate_road_21.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_21.jpg",
  },
  {
    original: "/pub-images/margate/margate_road_22.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_22.jpg",
  },
  {
    original: "/pub-images/margate/margate_road_23.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_23.jpg",
  },
  {
    original: "/pub-images/margate/margate_road_24.jpg",
    thumbnail: "/pub-images/margate/thumbnails/thumbnail_24.jpg",
  },
  {
    original: "/pub-images/new_picture_5.jpg",
    thumbnail: "/pub-images/thumbnails/new_picture_5_thumbnail.jpg",
  },
  {
    original: "/pub-images/new_picture_6.jpg",
    thumbnail: "/pub-images/thumbnails/new_picture_6_thumbnail.jpg",
  },
  {
    original: "/pub-images/new_picture_7.jpg",
    thumbnail: "/pub-images/thumbnails/new_picture_7_thumbnail.jpg",
  },
  {
    original: "/pub-images/new_picture_8.jpg",
    thumbnail: "/pub-images/thumbnails/new_picture_8_thumbnail.jpg",
  },
  {
    original: "/pub-images/new-build/12.jpg",
    thumbnail: "/pub-images/new-build/thumbnails/12_thumbnail.jpg",
  },
  {
    original: "/pub-images/new-build/13.jpg",
    thumbnail: "/pub-images/new-build/thumbnails/13_thumbnail.jpg",
  },
  {
    original: "/pub-images/new-build/14.jpg",
    thumbnail: "/pub-images/new-build/thumbnails/14_thumbnail.jpg",
  },
  {
    original: "/pub-images/new-build/15.jpg",
    thumbnail: "/pub-images/new-build/thumbnails/15_thumbnail.jpg",
  },
  {
    original: "/pub-images/new-build/16.jpg",
    thumbnail: "/pub-images/new-build/thumbnails/16_thumbnail.jpg",
  },
  {
    original: "/pub-images/new-build/17.jpg",
    thumbnail: "/pub-images/new-build/thumbnails/17_thumbnail.jpg",
  },
  {
    original: "/pub-images/new_picture_9.jpg",
    thumbnail: "/pub-images/thumbnails/new_picture_9_thumbnail.jpg",
  },
  {
    original: "/pub-images/new_picture_10.jpg",
    thumbnail: "/pub-images/thumbnails/new_picture_10_thumbnail.jpg",
  },
  {
    original: "/pub-images/new_picture_11.jpg",
    thumbnail: "/pub-images/thumbnails/new_picture_11_thumbnail.jpg",
  },
  {
    original: "/pub-images/new_picture_12.jpg",
    thumbnail: "/pub-images/thumbnails/new_picture_12_thumbnail.jpg",
  },
];

class Gallery extends Component {
  render() {
    return (
      <div onContextMenu={(e) => e.preventDefault()}>
        <ImageGallery
          additionalClass="z-index-1"
          showNav={false}
          lazyLoad={true}
          showFullscreenButton={false}
          showPlayButton={false}
          autoPlay={true}
          slideInterval="6000"
          thumbnailPosition="top"
          items={images}
        />
      </div>
    );
  }
}

export default Gallery;
