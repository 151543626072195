//This pulls blog posts from a WordPress API somewhere, using Axios. The other related file is Postview.js.
import React, { Component } from 'react'; //import the component part of React
//Packages
import axios from "axios";
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';

class Blogpost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: {},
    };
    this.createMarkup = this.createMarkup.bind();
  }

  componentDidMount() {
    const slug = this.props.match.params.slug;
    axios
    .get(`https://lykkehaus.com/wp/wp-json/wp/v2/posts?slug=${slug}`)
    .then(post => {
      this.setState({
        post: post.data[0]
      });
    });
  }

  createMarkup(html) {
    return { __html: html };
  }

  render() {
    let build;
    if (this.state.post.title) {
      build = (
        <div className="blog-post">
        <Helmet>
        <title>Lykke Haus Blog - {this.state.post.title.rendered}</title>
        <meta name="description" content={this.state.post.excerpt.rendered}/>
        <meta property="og:title" content={this.state.post.title.rendered}/>
        <meta property="og:description" content={this.state.post.excerpt.rendered}/>
        <meta property="og:image" content={this.state.post.fimg_url}/>
        </Helmet>
        <div className="parallax-home-200" id="blog-header"/>
        <Container className="pt-3 pb-3">
        <h1 className="text-center intro-text">{this.state.post.title.rendered}</h1>
        <div dangerouslySetInnerHTML={this.createMarkup( this.state.post.content.rendered )} />
        <div className="text-center">
        <Link to="/blog/">
        <Button variant="dark" size="sm" className="blog-button">Back to the Blog</Button>
        </Link>
        </div>
        </Container>
        </div>
      );
    } else {
      build = <div />;
    }
    return build;
  }
}

export default Blogpost;
