import React, { Component } from 'react';
//Packages
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet';

class AboutPage extends Component {
  render() { return(
    <div className="AboutPage">

    <Helmet>
    <title>Lykke Haus - About Us</title>
    <meta name="description" content="Luxurious, boutique accommodation designed with you in mind. Property management for those looking for a remarkable standard of service. Based in Thanet, Kent." />
    <meta property="og:title" content="Lykke Haus - About Us" />
    <meta property="og:description" content="Luxurious, boutique accommodation designed with you in mind. Property management for those looking for a remarkable standard of service. Based in Thanet, Kent." />
    <meta property="og:image" content="https://lykkehaus.com/og-image.jpg"/>
    </Helmet>
  <div className="parallax-home-200" id="about-page-header"/>
    <Container>
    <Row>
    <Col className="ml-auto mr-auto">
    <h1 className="mt-4 mb-4">About us</h1>
    <p>We’re property investors ourselves, with portfolios spread out across Thanet, Kent. We decided to found our agency to be a one-stop shop for everything that every landlord can wish for: excellent and efficient management, affordable rates, clear transparency and amazing customer service. There are no hidden fees and no hidden agenda: we are here to make property life easy.</p>
    </Col>
    </Row>
    <Row>
    <Col className="ml-auto mr-auto">
    <h2 className="mb-4">Expert Property Management</h2>
    <p>Our aim was simple: to give property owners a consistently remarkable level of service across Thanet. In order to achieve this, our first step was to eliminate the most common things that go wrong.</p>
    <p>As investors, our biggest frustration with the letting agency was that every time we had to speak to a different person, that is, if they finally pick up the phone. We decided that to provide the most efficient service there is only one way to solve this problem: property manage allocation. Once you join, your portfolio will be looked after by an allocated property manager and they will be your one and only point of contact who will always be at the top of things.</p>
    <p>All our staff members are very passionate about property and we only employ the best in the field. As such, you will never get to speak to someone who is rude, unprofessional or just seems absent.</p>
    <p>All our staff uses the best available software available to achieve the most smooth and efficient property management.</p>
    </Col>
    </Row>
    <Row>
    <Col className="mb-5 ml-auto mr-auto">
    <h2 className="mb-4">How do we work?</h2>
    <ul>
    <li>Local knowledge and personal touch is vital in property, thus all our team members are based in Thanet, Kent.</li>
    <li>All the tradesmen that we use for maintenance jobs are locals too, which ensures the best competitive rates and most efficient and reliable service.</li>
    <li>Our staff uses the best available cloud technology and we strive to uses as minimum paper as possible for easy of communication and all of the transactions.</li>
    </ul>
    </Col>
    </Row>
    </Container>
</div>

);
}
}

export default AboutPage;
