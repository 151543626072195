import React from 'react';
//Pages
import Homepage from './pages/Homepage.js';
import Blog from './pages/Blog.js';
import Blogpost from './pages/Blogpost.js';
import PrivacyPage from './pages/Privacypage.js';
import ContactPage from './pages/Contactpage.js';
import AboutPage from './pages/Aboutpage.js';
import ServicePage from './pages/Servicepage.js';
import FurniturePage from './pages/Furniture.js';
import FeesPage from './pages/Feespage.js';
import FAQpage from './pages/FAQpage.js';
//Structure
import Navigationbar from './structure/navigationbar.js';
import Footer from './structure/footer.js';
//Router
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

//Navigation - Router and footer. The content between the switch renders as functions.
export default function App() {
  return (
    <div className="App">
    <Router>
    <Navigationbar/>
    <Switch>
    <Route exact path="/" component={Homepage}/>
    <Route exact path="/about/" component={AboutPage}/>
    <Route exact path="/services/" component={ServicePage}/>
    <Route exact path="/furniture-rental/" component={FurniturePage}/>
    <Route exact path="/fees/" component={FeesPage}/>
    <Route exact path="/FAQ/" component={FAQpage}/>
    <Route exact path="/contact/" component={ContactPage}/>
    <Route exact path="/blog/" component={Blog}/>
    <Route path="/blog/:slug/" component={Blogpost}/>

    <Route exact path="/privacy-policy" component={PrivacyPage}/>
    </Switch>
    </Router>
    <Footer/>
    </div>
  );
}
