import React, { Component } from 'react';
//Includes
import Cookienotice from '../includes/cookienotice.js';
//Packages
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

class Footer extends Component {

  getYear() {
    return new Date().getFullYear();
}

  render() { return(
    <div>
    <footer className="footer">
    <Container>
    <Row>
    <Col className="d-flex justify-content-center">
    <span className="text-center mt-4 mb-4" id="copyright">Copyright &copy; {this.getYear()} Two Ravens Ltd trading as "Lykke Haus": company number 09846309.<br/><br/> Registered Office Address: Two Ravens Ltd | 1 Market Hill, Calne, Wiltshire, SN11 0BT. Tel: <a href="tel:0441843265040">01843 265040.</a><br/><br/><a href="/privacy-policy">Privacy Policy</a></span>
    </Col>
    </Row>
    </Container>
    <Cookienotice/>
    </footer>
    </div>
  );
}
}

export default Footer;
