import React, { Component } from 'react';
//Images
import LykkeLarge from '../images/lykkeLogoLarge.svg'
//Packages
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet';
//gallery
import Gallery from '../includes/gallery.js';
//Contact form
import ContactForm from '../includes/contactform.js';

class Homepage extends Component {
  render() { return(
    <div className="Home">

    <Helmet>
    <title>Lykke Haus - Co-Living, Property Management and Single Lets</title>
    <meta name="description" content="Luxurious, boutique accommodation designed with you in mind. Property management for those looking for a remarkable standard of service. Based in Thanet, Kent." />
    <meta property="og:title" content="Lykke Haus - Co-Living, Property Management and Single Lets" />
    <meta property="og:description" content="Luxurious, boutique accommodation designed with you in mind. Property management for those looking for a remarkable standard of service. Based in Thanet, Kent." />
    <meta property="og:image" content="https://lykkehaus.com/og-image.jpg"/>
    </Helmet>

  <div className="parallax-home-700">
        <div className="home-title-top">
                <Row className="top-row">
                <Col xl="4"><img src={LykkeLarge} className="LykkeLarge mb-1 mt-1" alt="Lykke Haus Logo"/></Col>
                <Col xl="8" className="title-area-text">
                  <p className="mt-2 top-text">Luxurious, boutique accommodation designed with you in mind.</p>
                    <p className="top-text">Property management for those looking for a remarkable standard of service.</p>
                    <Row className="mt-2">
              <Col xl="3" className="feature-title"><h5>CO-LIVING.</h5></Col>
                <Col xl="6" className="feature-title"><h5>SERVICED ACCOMODATION.</h5></Col>
              <Col xl="3" className="feature-title"><h5>SINGLE LETS.</h5></Col>
              </Row>
                </Col>
                </Row>
          </div>
      </div>



<div className="parallax-home-700-teal">
<img src="/pub-images/lykke.svg" className="lykkehausoverlay d-none d-md-block" alt="lykkehaus text" onContextMenu={(e)=> e.preventDefault()}/>
<div className="home-title-teal z-index-1">
      <Col className="text-center mt-4">
        <h1 className="white-text">Our Philosophy</h1>
        <p class="home-title-text white-text">You’re probably wondering what “Lykke Haus” means. Lykke (pronounced /løkə/, [ˈløɡ̊ə], or Luuh Kah) is the Danish word for HAPPINESS. It's a concept which denotes that at the root of everything, we are looking to feel comfortable. The way an interior looks is as important as its function, and those in Scandinavia have this down to a fine art.</p>
      </Col>
</div>
</div>

<Gallery/>

<div className="parallax-home-1000-teal">
<div className="home-title-teal-left">
<h1 className="mt-2 accent-text">Why go to Lykke Haus?</h1>
<Row className="mt-2 p-4">
<Col xs="12" xl="3">
<h4 className="accent-text">Remarkable.</h4>
<p className="white-text">Being better than the average letting agent isn’t much of a goal, so we’re aiming much higher. You and your tenants are going to have a remarkably stress-free, hassle-free and happy time of it with Lykke Haus – we promise.</p>
</Col>
<Col xs="12" xl="3">
<h4 className="accent-text">Paperless.</h4>
<p className="white-text">Yep, we’re green as well as yellow. Cutting out paper means that you can sign documents and read your management statements from your phone or laptop – and for us, it means we can run faster, more efficient processes on your behalf.</p>
</Col>
<Col xs="12" xl="3">
<h4 className="accent-text">Personal.</h4>
<p className="white-text">We combine our dedicated local teams with a central hub, which means you only need to call one person to get the lowdown on your whole portfolio – however spread out your properties are.</p>
</Col>
<Col xs="12" xl="3">
<h4  className="accent-text">Honest.</h4>
<p className="white-text">There’s no need to dig out your reading glasses, because all our print is unashamedly large. The price you see is the price you pay: no extras, no renewal fees, no nasty surprises.</p>
</Col>
</Row>
</div>
</div>

<div className="static-image-bg"/>

<Container>
<Row>
<Col className="text-center mt-5 mb-5 pb-5 pt-5">
<h1 className="mt-4 mb-4">Contact Us</h1>
<p>Please give us a call on <a href="tel:0441843265040">01843 265040</a> or contact us by email on <a href="mailto:maria@lykkehaus.com">maria@lykkehaus.com</a>.</p>
</Col>
</Row>
</Container>

</div>
  );
}
}

export default Homepage;
