import React, { Component } from 'react';
//Packages
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

class ServicePage extends Component {
  render() { return(
    <div className="ServicePage">

    <Helmet>
    <title>Lykke Haus - Our Service</title>
    <meta name="description" content="Lykke Haus is a provider of luxurious co-living, property management and serviced accomodation. 12% Letting Fee." />
    <meta property="og:title" content="Lykke Haus - Our Service" />
    <meta property="og:description" content="Lykke Haus is a provider of luxurious co-living, property management and serviced accomodation. 12% Letting Fee." />
    <meta property="og:image" content="https://lykkehaus.com/og-image.jpg"/>
    </Helmet>
  <div className="parallax-home-200" id="service-page-header"/>
    <Container>
    <Row>
    <Col className="ml-auto mr-auto mt-5 mb-5 text-center">
    <h3 className="text-center">One premium product, one fair price</h3>
    <Card style={{ width: '80%' }} className="ml-auto mr-auto">
    <Card.Header>Full property management with 'rent on time' guarantee</Card.Header>
    <Card.Header>12% + letting fee</Card.Header>
    <ListGroup variant="flush">
    <ListGroup.Item>Expert online and local marketing</ListGroup.Item>
    <ListGroup.Item>Accompanied property viewings</ListGroup.Item>
    <ListGroup.Item>Comprehensive tenant referencing</ListGroup.Item>
    <ListGroup.Item>Tenancy agreement drawn up</ListGroup.Item>
    <ListGroup.Item>Full inventory and schedule of condition</ListGroup.Item>
    <ListGroup.Item>Rent collection</ListGroup.Item>
    <ListGroup.Item>Deposit protected in approved government scheme</ListGroup.Item>
    <ListGroup.Item>Arrangement of repairs and maintenance work</ListGroup.Item>
    <ListGroup.Item>24-hour emergency line for tenants</ListGroup.Item>
    <ListGroup.Item>Regular property visits</ListGroup.Item>
    </ListGroup>
    </Card>
    <Link to="/contact/">
    <Button variant="dark" size="md" className="blog-button">Contact Us</Button>
    </Link>
    </Col>
    </Row>
    </Container>
</div>

);
}
}

export default ServicePage;
